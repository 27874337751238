import React from 'react';
import { NavLink } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Outlet,Navigate } from 'react-router-dom';
import { getSessionData } from './utils';
const homeUrl = process.env.REACT_APP_HOME_URL;


const Dashboard = () => {
  const user = getSessionData('user');
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
   <div>
      <div className="admin-main-section">
        <div className="container pt-5 pb-5">
            <div className="row">
              <div className="welcome-message">
                <h2 className="fw-bold">Welcome back!</h2>
                <p>Choose a tool to get started</p>
              </div>
            </div>
            <div className="row admin-grid-box gx-4 gy-4">
                <div className="col-md-4 col-sm-12">
                   <NavLink to="/emailchecker">
                      <div className="service-box">
                        <div className="box-icon">
                         <i className="far fa-envelope"></i>
                        </div>
                        <div className="box-content">
                            <h3>Email Verification</h3>
                            <p>Verify email addresses to ensure deliverability and maintain sender reputation</p>
                          </div>
                      </div>
                  </NavLink>
                </div>
                 {user.domains_tool !== '0' && (
                <div className="col-md-4 col-sm-12">
                    <NavLink to="/search-domain">
                      <div className="service-box">
                        <div className="box-icon">
                        <i className="fas fa-inbox"></i>
                        </div>
                        <div className="box-content">
                            <h3>Inbox Setup</h3>
                            <p>Verify email addresses to ensure deliverability and maintain sender reputation</p>
                          </div>
                      </div>
                  </NavLink>
                </div>
                )}
                {user.warm_up_tool !== '0' &&( 
                <div className="col-md-4 col-sm-12">
                    <NavLink to="/warmup-dashboard">
                      <div className="service-box">
                        <div className="box-icon">
                        <i className="fas fa-bolt"></i>
                        </div>
                        <div className="box-content">
                            <h3>Email Warm-up</h3>
                            <p>Verify email addresses to ensure deliverability and maintain sender reputation</p>
                          </div>
                      </div>
                  </NavLink>
                </div>
                )}
            </div>

        </div>
      </div>
    </div>
  );
};

export default Dashboard;
