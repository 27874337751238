import React, { useState, useRef } from "react";
import { getSessionData } from "./utils";
import Papa from "papaparse";

const apiUrl = process.env.REACT_APP_API_URL;
const user = getSessionData("user");

const EmailUpload = ({ refreshData }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!file) {
      alert("Please select a CSV file.");
      return;
    }

    setUploading(true);

    Papa.parse(file, {
      header: false,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;

        let emails = data.slice(1).map((row) => ({
          email: row[0],
          first_name: row[1],
          last_name: row[2],
          imap_username: row[3],
          imap_password: row[4],
          imap_host: row[5],
          imap_port: row[6],
          user_id: user?.id || 1,
        }));

        try {
          const response = await fetch(`${apiUrl}/api/upload-email`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            },
            body: JSON.stringify({ emails }),
          });

          const responseData = await response.json();
          if (response.ok) {
            alert("Emails uploaded successfully!");
            refreshData(); 
            fileInputRef.current.value = ""; // Reset the file input
            setFile(null); // Reset the file state
            setFileName(""); // Reset the file name
          } else {
            alert(`Upload failed: ${responseData.message}`);
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          alert("An error occurred while uploading the file.");
        } finally {
          setUploading(false);
        }
      },
    });
  };

  return (
    <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
      <div className="csv_upload_boxsec">
        <form onSubmit={handleUpload}>
          <div className="upload_box">
            <input
              type="file"
              className="form-control"
              onChange={handleFileChange}
              accept=".csv"
              ref={fileInputRef}
            />
            <div className="upload_txt">
              <i className="fas fa-upload"></i>
              <div className="contnt_txt">
                <p className="text-dark">{fileName ? fileName : "Choose a file or drag it here."}</p>
              </div>
            </div>
          </div>

          {uploading && (
            <div className="loader_mainsec">
                    <div className="spinner"></div>
                  </div>
          )}

          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="btn_mainsec">
              <button type="submit" className="btn_style" disabled={uploading}>
                {uploading ? "Uploading..." : "Submit"}
              </button>
              <a
                href="https://stagingadmin.automate-emailscale.io/public/sample_upload_emails.csv"
                className="btn_style download_s_btn"
                download
              >
                Download Sample File
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailUpload;
