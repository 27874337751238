import React, { useState, useEffect, useCallback } from 'react';
import { getSessionData } from './utils';
import {NavLink } from 'react-router-dom';
import Campaigns from "./Campaigns";
const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;


const WarmupDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [warmup1, setWarmup1] = useState(0);
  const [warmup2, setWarmup2] = useState(0);
  const [warmup3, setWarmup3] = useState(0);
  const [warmup4, setWarmup4] = useState(0);
  const [warmup5, setWarmup5] = useState(0);
  const [gmail, setGmail] = useState(0);
  const [microsoft, setMicrosoft] = useState(0);
  const [aol, setAol] = useState(0);
  const [yahoo, setYahoo] = useState(0);
  const [others, setOthers] = useState(0);
  const user = getSessionData('user');

  useEffect(() => {
    if (user && user.id) {
      fetchWarmupemails();
    } else {
      setLoading(false);
    }
  }, []);

   if (!user) {
  return null; // Prevent React from removing nodes abruptly
}

  const fetchWarmupemails = async () => {
    setLoading(true);
    const endpoint = `get-warmup-emails?user_id=${user.id}`; 
    try {
      const response = await fetch(`${apiUrl}/api/${endpoint}`);
      if (!response.ok) {
        throw new Error('Network response was not ok'); 
      }
      const data = await response.json();
      setWarmup1(data.warmup1);
      setWarmup2(data.warmup2);
      setWarmup3(data.warmup3);
      setWarmup4(data.warmup4);
      setWarmup5(data.warmup5);
      setGmail(data.providers.gmail);
      setMicrosoft(data.providers.microsoft);
      setAol(data.providers.aol);
      setYahoo(data.providers.yahoo);
      setOthers(data.providers.others);
    } catch (error) {
      console.error('Error fetching email campaigns:', error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div className="container">
     <>
        <div className="addemails_mainbox">
          <div className="overall_performance whitebox">
            <div className="title_box">
              <h2>Overall Performance</h2>
            </div>

            {user.warm_up_tool !== '0' && (
              <div className="performance_boxsec">
                <div className="performance_box lightpink">
                  <div className="topbox">
                    0-25%
                    <span>Total Email</span>
                  </div>

                  <div className="infobox">
                    <h3>{warmup1}</h3>
                  </div>
                </div>

                <div className="performance_box lightgreen">
                  <div className="topbox">
                    25-45%
                    <span>Total Emails</span>
                  </div>

                  <div className="infobox">
                    <h3>{warmup2}</h3>
                  </div>
                </div>

                <div className="performance_box lightpurple">
                  <div className="topbox">
                   45-75%
                    <span>Total Emails</span>
                  </div>

                  <div className="infobox">
                    <h3>{warmup3}</h3>
                  </div>
                </div>

                <div className="performance_box lightred">
                  <div className="topbox">
                    75-90%
                    <span>Total Emails</span>
                  </div>

                  <div className="infobox">
                    <h3>{warmup4}</h3>
                  </div>
                </div>

                <div className="performance_box lightblue">
                  <div className="topbox">
                    90-100%
                    <span>Total Emails</span>
                  </div>

                  <div className="infobox">
                    <h3>{warmup5}</h3>
                  </div>
                </div>
              </div>

            )}

          
          </div>
        </div>

        
      </>
     <Campaigns />
      {/*<div className="new-box-style">
      {user.warm_up_tool !== '0' &&(
        <div className="warmup_dashboard">         
          <div className="number_of_emails">
            <div class="services_box">
                <div class="title_box">
                  <h3>0-25%</h3>
                </div>

                <div class="contnt_box">
                  <h4>Total Emails</h4>
                  <p>{warmup1}</p>
                </div>
            </div>
          </div>

          <div className="number_of_emails">
            <div class="services_box">
                <div class="title_box">
                  <h3>25-45%</h3>
                </div>
                <div class="contnt_box">
                  <h4>Total Emails</h4>
                  <p>{warmup2}</p>
                </div>
            </div>
          </div>

          <div className="number_of_emails">
            <div className="services_box">
                <div className="title_box">
                  <h3>45-75%</h3>
                </div>
                <div className="contnt_box">
                  <h4>Total Emails</h4>
                  <p>{warmup3}</p>
                </div>
            </div>
          </div>

          <div className="number_of_emails">
            <div className="services_box">
                <div className="title_box">
                  <h3>75-90%</h3>
                </div>
                <div className="contnt_box">
                  <h4>Total Emails</h4>
                  <p>{warmup4}</p>
                </div>
            </div>
          </div>

          <div className="number_of_emails">
            <div className="services_box">
                <div className="title_box">
                  <h3>90-100%</h3>
                </div>
                <div className="contnt_box">
                  <h4>Total Emails</h4>
                  <p>{warmup5}</p>
                </div>
            </div>
          </div>

          <div className="number_of_emails">
            <div className="services_box">
                <div className="contnt_box">
                  <h4>Gmail Accounts</h4>
                  <p>{gmail}</p>
                </div>
            </div>
          </div>

          <div className="number_of_emails">
            <div className="services_box">
                <div className="contnt_box">
                  <h4>Microsoft Accounts</h4>
                  <p>{microsoft}</p>
                </div>
            </div>
          </div>

          <div className="number_of_emails">
            <div className="services_box">
                <div className="contnt_box">
                  <h4>Aol Accounts</h4>
                  <p>{aol}</p>
                </div>
            </div>
          </div>

          <div className="number_of_emails">
            <div className="services_box">
                <div className="contnt_box">
                  <h4>Yahoo Accounts</h4>
                  <p>{yahoo}</p>
                </div>
            </div>
          </div>

          <div className="number_of_emails">
            <div className="services_box">
                <div className="contnt_box">
                  <h4>Others</h4>
                  <p>{others}</p>
                </div>
            </div>
          </div>
          
        </div>
        )}
        {loading && (
          <div className="loader_mainsec">
            <div className="spinner"></div>
          </div>
        )}
      </div>*/}

     
      </div>
   
    );
  };

export default WarmupDashboard;
