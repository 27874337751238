import React, { useState, useEffect } from 'react';
import { getSessionData } from './utils';
import { useSearchParams, Navigate, Link } from 'react-router-dom';

const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const user = getSessionData('user');

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [total_campaigns, setTotalCampaigns] = useState(0);
  const [loading, setLoading] = useState(true);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [popupData, setPopupData] = useState({ show: false, type: '', value: '' });
  const [editPopup, setEditPopup] = useState({ show: false, subject: '', message: '' });
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(searchParams.get('status'));
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: 'warmup_emails', direction: "asc" });

  useEffect(() => {
    if (user && user.id) {
      fetchCampaigns(page);
      fetchTotalpage();
    } else {
      setLoading(false);
    }
  }, [page, sortConfig]);


  const fetchTotalpage = async () => {
    const endpoint = `get-total-campaing-page?user_id=${user.id}&q=${query}`; 
    try {
      const response = await fetch(`${apiUrl}/api/${endpoint}`);
      if (!response.ok) {
        throw new Error('Network response was not ok'); 
      }
      const data = await response.json(); 
      var total_page = Math.round(data.total_pages/20);
      setTotalPage(total_page);
      setTotalCampaigns(data.total_pages);
    } catch (error) {
      console.error('Error fetching email campaigns:', error);
    } finally {

    }
  };

  const fetchCampaigns = async (page) => {
    setLoading(true);
    const endpoint = `list-email-campaigns?user_id=${user.id}&q=${query}&p=${page}&perPage=20&sortKey=${sortConfig.key}&sortDirection=${sortConfig.direction}`; 
    try {
      const response = await fetch(`${apiUrl}/api/${endpoint}`);
      if (!response.ok) {
        throw new Error('Network response was not ok'); 
      }
      const data = await response.json();
      setCampaigns(data); 
      console.log(data);
      // setTotalCampaigns(data.length);
    } catch (error) {
      console.error('Error fetching email campaigns:', error);
    } finally {
      setLoading(false); 
    }
  };

  const get_campaign_pagination = async (page) => {
    setPage(page);
    fetchCampaigns(page);
    setAreAllSelected(false);
    setSelectedCampaigns([]);
  };

  const handleKeyUp = (event) => {
    setPage(1);
    fetchTotalpage();
    if (event.key === "Enter") {
      fetchCampaigns(1);
    }
  };

  const fetchCampaignsclick = (event) => {
    setPage(1);
    fetchCampaigns(1);
  };

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setAreAllSelected(checked);
    setSelectedCampaigns(checked ? campaigns.map((c) => c.id) : []);
  };

  const handleRowSelect = (id) => {
    const updatedSelection = selectedCampaigns.includes(id)
      ? selectedCampaigns.filter((campaignId) => campaignId !== id)
      : [...selectedCampaigns, id];
    setSelectedCampaigns(updatedSelection);
    setAreAllSelected(updatedSelection.length === campaigns.length);
  };



  const handleDeleteSelected = async () => {
  if (selectedCampaigns.length === 0) {
    alert('No campaigns selected');
    return;
  }
  const isConfirmed = window.confirm("Are you sure you want to delete the selected campaigns?");
  if (!isConfirmed) return;

  try {
    setLoading(true);
    const endpoint = 'delete_campaigns'; // Use the same endpoint
    const response = await fetch(`${apiUrl}/api/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: selectedCampaigns }), // Send the selected IDs
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    alert('Selected campaigns deleted successfully');
    // Update the local state to remove the deleted campaigns
    const remainingCampaigns = campaigns.filter(
      (campaign) => !selectedCampaigns.includes(campaign.id)
    );
    setCampaigns(remainingCampaigns);
    setSelectedCampaigns([]);
    setAreAllSelected(false);
  } catch (error) {
    console.error('Error deleting campaigns:', error);
    alert("Failed to delete campaigns. Please try again.");
  } finally {
    setLoading(false);
  }
  };

  const handleDownloadCSV = () => {
    if (selectedCampaigns.length === 0) {
      alert('No campaigns selected for download');
      return;
    }

    const selectedData = campaigns.filter((campaign) =>
      selectedCampaigns.includes(campaign.id)
    );

    const csvData = selectedData.map((campaign) => ({
      Name: `${campaign.first_name} ${campaign.last_name}`,
      Email: campaign.smtp_username,
      Provider: 'SMTP',
      DailyLimit: `${campaign.total_emails} / ${campaign.daily_limit}`,
      WarmupEnabled: campaign.warmup_enabled === 'TRUE' ? 'Yes' : 'No',
      Reputation: `${campaign.warmup_emails}%`,
    }));

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [
        Object.keys(csvData[0]).join(','), // CSV headers
        ...csvData.map((row) => Object.values(row).join(',')), // CSV rows
      ].join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'campaigns.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRunCampaign = async (id) => {
    setLoading(true);
    const endpoint = 'run_campaign';
    const response = await fetch(`${apiUrl}/api/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, 'user_id': user.id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    setLoading(false);
    const data1 = await response.json();
    console.log(data1);
    alert(data1.msg);
    window.location.reload();
    return data1;
  };

  const handleDeleteCampaign = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this email account?");
    if (isConfirmed) {
      try {
        setLoading(true);
        const endpoint = 'delete_campaign';
        const response = await fetch(`${apiUrl}/api/${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        alert('Email account deleted successfully');
        setLoading(false);
        const data1 = await response.json();
        window.location.reload();
        return data1;
      } catch (error) {
        setLoading(true);
        alert("Failed to delete the email account. Please try again.");
      }
    } else {
      console.log("Campaign deletion canceled.");
    }
  };

  const handleUpdateLimit = (type) => {
    console.log("Opening popup for:", type); // Debugging
    const selectedCampaign = campaigns.find((campaign) => selectedCampaigns.includes(campaign.id));
    const value = selectedCampaign ? selectedCampaign[type] : ''; // Set value based on the type (daily_limit or warmup_limit)
    setPopupData({ show: true, type, value }); // Open popup with pre-filled value
  };

  const handlePopupChange = (e) => {
    setPopupData({ ...popupData, value: e.target.value }); // Update the value entered by the user in the popup
  };

  const handlePopupSubmit = async () => {
    const { type, value } = popupData;

    if (!value) {
      alert('Please enter a value');
      return;
    }

    try {
      setLoading(true);

      const endpoint = 'update-limits'; // Use the update-limits endpoint
      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids: selectedCampaigns, // Array of selected campaign IDs
          daily_limit: type === 'daily_limit' ? value : undefined, // Send daily_limit only if type is 'daily_limit'
          warmup_limit: type === 'warmup_limit' ? value : undefined, // Send warmup_limit only if type is 'warmup_limit'
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      
      // Update the local campaigns state with the new values
      setCampaigns((prevCampaigns) => {
        return prevCampaigns.map((campaign) => {
          if (selectedCampaigns.includes(campaign.id)) {
            if (type === 'daily_limit') {
              campaign.daily_limit = value; // Update daily limit
            } else if (type === 'warmup_limit') {
              campaign.warmup_limit = value; // Update warmup limit
            }
          }
          return campaign;
        });
      });

     alert(`${type === 'daily_limit' ? 'Daily limit' : 'Warmup limit'} updated successfully`);
          setSelectedCampaigns([]);
      setAreAllSelected(false);
      setPopupData({ show: false, type: '', value: '' }); // Close popup after successful update

    } catch (error) {
      console.error('Error updating limits:', error);
      alert("Failed to update limits. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCampaign = () => {
    const selected = campaigns.find(c => c.id === selectedCampaigns[0]);
    setEditPopup({
      show: true,
      subject: selected.subject,
      message: selected.campaign_message,
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditPopup({ ...editPopup, [name]: value });
  };

  const submitCampaignUpdate = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${apiUrl}/api/update-campaign`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ids: selectedCampaigns,
          subject: editPopup.subject,
          campaign_message: editPopup.message,
        }),
      });

      if (!response.ok) throw new Error('Failed to update');

      alert('Campaign updated successfully');
      setEditPopup({ show: false, subject: '', message: '' });
      window.location.reload();
    } catch (error) {
      alert('Failed to update campaign');
    } finally {
      setLoading(false);
    }
  };


  if(!user) {
    return <Navigate to="/login" />;
  }

  if(user.warm_up_tool  === '0') {
    alert("you don't have to access this tool");
    return <Navigate to="/dashboard" />;
  }

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSelectedCampaigns([]);
    setAreAllSelected(false);
    setSortConfig({ key, direction });
    setPage(1);  // Reset to first page
  };

  return (
    <>
      <div className="campaign_list test ">
        <div className="tophead_mainsec">
          <div className="row">

            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="status_mainsec">
                {status === 'true' && <p>Account added successfully</p>}
                {status === 'false' && <p>Account already exists</p>}
                {status === 'error' && <p className="error">Something went wrong, please try again later</p>}
              </div>
            </div>
            </div>
            <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="btn_mainsec">
                {selectedCampaigns.length > 0 && (
                <>
                  <button className="btn_style m-2" onClick={handleDeleteSelected}>
                    Delete Selected
                  </button>
                  <button className="btn_style m-2" onClick={handleDownloadCSV}>
                    Download CSV
                  </button>
                 <button className="btn_style m-2" onClick={() => handleUpdateLimit('daily_limit')}>
                    Update Daily Limit
                  </button>
                  <button className="btn_style m-2" onClick={() => handleUpdateLimit('warmup_limit')}>
                    Update Warmup Limit
                  </button>
                    <button className="btn_style m-2" onClick={handleUpdateCampaign}>
                      Update Subject & Message
                    </button>
                </>
              )}
              </div>            
            </div>
          </div>
         </div>
         <div className="email_listing_mainsec p-0">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="filter_boxsec">
                <div className="filtersec">
                  <input 
                    className="form-control" 
                    type="text" 
                    placeholder="Search"
                    onChange={(e) => setQuery(e.target.value)}
                    value={query}
                    onKeyUp={handleKeyUp}
                  />
                  <div className="btn_mainsec">
                    <button 
                      className="btn_style"
                      onClick={(e) => fetchCampaignsclick(1)}
                    >Search</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="card-body">
               <div className="addemails_mainbox">
                  <div className="connected_accounts whitebox">
                      <div className="title_box">
                        <div className="title_sec">
                          <label className="custom_checkbox">
                            <input
                              type="checkbox"
                              onChange={handleSelectAllChange}
                              checked={areAllSelected}
                            />
                            <span className="checkmark"></span>
                          </label>

                          <h2>Connected Accounts {total_campaigns}</h2>
                        </div> 
                      <Link className="btn_style" to={`${homeUrl}/connect-accounts`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus w-5 h-5"><path d="M5 12h14"></path><path d="M12 5v14"></path></svg>
                        <span>Add Account</span>
                      </Link>
                    </div>
                    <div className="table_mainbox">
                    <div className="table-responsive">
                      <table className="table">
                        {/*<thead>
                        <tr>
                          <th>
                            <label className="custom_checkbox">
                              <input
                                type="checkbox"
                                onChange={handleSelectAllChange}
                                checked={areAllSelected}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </th>
                          <th onClick={() => handleSort("first_name")} style={{ cursor: "pointer" }}>
                          Name
                          <span style={{ marginLeft: "5px", fontSize: "10px", color: sortConfig.key === "full_name" && sortConfig.direction === "asc" ? "#d477c9" : "#ccc" }}>
                            ↑
                          </span>
                          <span style={{ marginLeft: "2px", fontSize: "10px", color: sortConfig.key === "full_name" && sortConfig.direction === "desc" ? "#d477c9" : "#ccc" }}>
                            ↓
                         </span>
                          </th>
                         {user.id === 1 && (
                        <th onClick={() => handleSort("first_name")} style={{ cursor: "pointer" }}>
                          User Name
                          <span style={{ marginLeft: "5px", fontSize: "10px", color: sortConfig.key === "first_name" && sortConfig.direction === "asc" ? "#d477c9" : "#ccc" }}>
                            ↑
                          </span>
                          <span style={{ marginLeft: "2px", fontSize: "10px", color: sortConfig.key === "first_name" && sortConfig.direction === "desc" ? "#d477c9" : "#ccc" }}>
                            ↓
                         </span>
                        </th>
                        )}
                        <th onClick={() => handleSort("smtp_username")} style={{ cursor: "pointer" }}>
                        Email Address
                        <span style={{ marginLeft: "5px", fontSize: "10px", color: sortConfig.key === "smtp_username" && sortConfig.direction === "asc" ? "#d477c9" : "#ccc" }}>
                            ↑
                          </span>
                          <span style={{ marginLeft: "2px", fontSize: "10px", color: sortConfig.key === "smtp_username" && sortConfig.direction === "desc" ? "#d477c9" : "#ccc" }}>
                            ↓
                        </span>
                        </th>
                        <th onClick={() => handleSort("email_provider")} style={{ cursor: "pointer" }}>
                          Email Provider
                          <span style={{ marginLeft: "5px", fontSize: "10px", color: sortConfig.key === "email_provider" && sortConfig.direction === "asc" ? "#d477c9" : "#ccc" }}>
                            ↑
                          </span>
                          <span style={{ marginLeft: "2px", fontSize: "10px", color: sortConfig.key === "email_provider" && sortConfig.direction === "desc" ? "#d477c9" : "#ccc" }}>
                            ↓
                          </span>
                        </th>
                        <th onClick={() => handleSort("warmup_limit")} style={{ cursor: "pointer" }}>
                          Warmup Limit
                          <span style={{ marginLeft: "5px", fontSize: "10px", color: sortConfig.key === "warmup_limit" && sortConfig.direction === "asc" ? "#d477c9" : "#ccc" }}>
                            ↑
                          </span>
                          <span style={{ marginLeft: "2px", fontSize: "10px", color: sortConfig.key === "warmup_limit" && sortConfig.direction === "desc" ? "#d477c9" : "#ccc" }}>
                            ↓
                          </span>
                        </th>
                        <th onClick={() => handleSort("daily_limit")} style={{ cursor: "pointer" }}>
                          Daily Limit 
                          <span style={{ marginLeft: "5px", fontSize: "10px", color: sortConfig.key === "daily_limit" && sortConfig.direction === "asc" ? "#d477c9" : "#ccc" }}>
                            ↑
                          </span>
                          <span style={{ marginLeft: "2px", fontSize: "10px", color: sortConfig.key === "daily_limit" && sortConfig.direction === "desc" ? "#d477c9" : "#ccc" }}>
                            ↓
                          </span>
                        </th>
                        <th onClick={() => handleSort("total_email")} style={{ cursor: "pointer" }}>
                          Warmup Emails Sent 
                          <span style={{ marginLeft: "5px", fontSize: "10px", color: sortConfig.key === "total_email" && sortConfig.direction === "asc" ? "#d477c9" : "#ccc" }}>
                            ↑
                          </span>
                          <span style={{ marginLeft: "2px", fontSize: "10px", color: sortConfig.key === "total_email" && sortConfig.direction === "desc" ? "#d477c9" : "#ccc" }}>
                            ↓
                          </span>
                        </th>
                        <th onClick={() => handleSort("warmup_enabled")} style={{ cursor: "pointer" }}>
                          Warmup Enabled
                          <span style={{ marginLeft: "5px", fontSize: "10px", color: sortConfig.key === "warmup_enabled" && sortConfig.direction === "asc" ? "#d477c9" : "#ccc" }}>
                            ↑
                          </span>
                          <span style={{ marginLeft: "2px", fontSize: "10px", color: sortConfig.key === "warmup_enabled" && sortConfig.direction === "desc" ? "#d477c9" : "#ccc" }}>
                            ↓
                          </span>
                        </th>
                        <th onClick={() => handleSort("warmup_emails")} style={{ cursor: "pointer" }}>
                          Reputation 
                          <span style={{ marginLeft: "5px", fontSize: "10px", color: sortConfig.key === "warmup_emails" && sortConfig.direction === "asc" ? "#d477c9" : "#ccc" }}>
                            ↑
                          </span>
                          <span style={{ marginLeft: "2px", fontSize: "10px", color: sortConfig.key === "warmup_emails" && sortConfig.direction === "desc" ? "#d477c9" : "#ccc" }}>
                            ↓
                          </span>
                        </th>
                          <th>Action</th>
                        </tr>
                      </thead>*/}
                        <tbody>
                           {campaigns.length ? (
                            campaigns.map((campaign) => (
                            <tr key={campaign.id}>
                              <td>
                                <label className="custom_checkbox">
                                  <input
                                    type="checkbox"
                                    checked={selectedCampaigns.includes(campaign.id)}
                                    onChange={() => handleRowSelect(campaign.id)}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </td>
                              <td>
                                <div className="iconname_box">
                                  <div className="iconbox">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="lucide lucide-chrome w-6 h-6 text-primary-500"
                                    >
                                      <circle cx="12" cy="12" r="10"></circle>
                                      <circle cx="12" cy="12" r="4"></circle>
                                      <line x1="21.17" x2="12" y1="8" y2="8"></line>
                                      <line x1="3.95" x2="8.54" y1="6.06" y2="14"></line>
                                      <line x1="10.88" x2="15.46" y1="21.94" y2="14"></line>
                                    </svg>
                                  </div>
                                  <div className="contntbox">
                                     <h4> <a href={`${homeUrl}/campaign-emails/${campaign.id}`}>
                                      {campaign.smtp_username}
                                      </a></h4>
                                    <p>{campaign.email_provider}</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="infotxt">
                                  <h3>{campaign.total_emails}</h3>
                                  <p>Emails Sent</p>
                                </div>
                              </td>
                              <td>
                                <div className="infotxt greentxt">
                                  <h3>
                                    {campaign.total_emails > 0 
                                      ? (((campaign.send_email || 0) - (campaign.spam_email || 0)) / campaign.total_emails * 100).toFixed(2) + "%" 
                                      : "0%"}
                                  </h3>
                                  <p>Inbox Rate</p>
                                </div>
                              </td>
                              <td>
                                <div className="infotxt purpletxt">
                                  <h3>{campaign.spam_email}</h3>
                                  <p>Saved from Spam</p>
                                </div>
                              </td>
                              {/*<td>
                                <div className="infotxt redtxt">
                                  <h3>{campaign.spam_email}</h3>
                                  <p>Spam Count</p>
                                </div>
                              </td>*/}
                              <td>
                                <div className="infotxt bluetxt">
                                  <h3>{campaign.warmup_emails}</h3>
                                  <p>Health Score</p>
                                </div>
                              </td>
                              <td>
                                <div className="action_btn">
                                  {/*<button>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="lucide lucide-settings w-5 h-5"
                                    >
                                      <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
                                      <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                  </button> */}
                                  <button className="remove_btn" onClick={() => handleDeleteCampaign(campaign.id)}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="lucide lucide-trash2 w-5 h-5"
                                    >
                                      <path d="M3 6h18"></path>
                                      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                                      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                                      <line x1="10" x2="10" y1="11" y2="17"></line>
                                      <line x1="14" x2="14" y1="11" y2="17"></line>
                                    </svg>
                                  </button>
                                </div>
                              </td>
                             </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">No campaigns found</td>
                        </tr>
                      )}
                    </tbody>
                      </table>
                    </div>
                    </div>
                  </div>
                </div>
              
              </div>
              <div className="main_pagination_section">
                <div className="btn_mainsec">
                  <button 
                      className="btn_style"
                      onClick={() => get_campaign_pagination(page-1)}
                      disabled={page === 1}
                  >
                      Previous
                  </button>

                  <span> Page {page} of {totalpage} </span>

                  <button 
                      className="btn_style"
                      onClick={() => get_campaign_pagination(page+1)}
                      disabled={totalpage === 0 || page === totalpage}
                  >
                      Next
                  </button>
                </div>
              </div>
            </div>
            </div>
          </div>
         </div> 
        {editPopup.show && (
        <div className="modal show fade infopopupModal d-block">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Message & Subject</h5>
                <button className="btn-close" onClick={() => setEditPopup({ show: false })}></button>
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control mb-3 text-dark"
                  name="subject"
                  value={editPopup.subject}
                  onChange={handleEditChange}
                  placeholder="Campaign Subject"
                />
                <textarea
                  className="form-control text-dark"
                  name="message"
                  value={editPopup.message}
                  onChange={handleEditChange}
                  placeholder="Campaign Message" 
                />
              </div>
              <div className="modal-footer">
                <div className="btn_mainsec">
                  <button className="btn_style" onClick={submitCampaignUpdate}>
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
        {popupData.show && (
          <div className="modal show fade d-block infopopupModal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Update {popupData.type === 'daily_limit' ? 'Daily Limit' : 'Warmup Limit'}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setPopupData({ show: false, type: '', value: '' })}
                  ></button>
                </div>
                <div className="modal-body">
                  <input
                    type="number"
                    className="form-control text-dark"
                    value={popupData.value}
                    onChange={handlePopupChange}
                    placeholder={`Enter new ${popupData.type === 'daily_limit' ? 'daily limit' : 'warmup limit'}`}
                  />
                </div>
                <div className="modal-footer">
                 <div className="btn_mainsec">
                    <button
                      type="button"
                      className="btn_style m-2"
                      onClick={() => setPopupData({ show: false, type: '', value: '' })}
                    >
                      Close
                    </button>
                    <button type="button" className="btn_style" onClick={handlePopupSubmit}>
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {loading && (
          <div className="loader_mainsec">
            <div className="spinner"></div>
          </div>
        )}
      </>
  
  );
};

export default Campaigns;
