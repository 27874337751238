import React, { useState, useEffect } from 'react';
import { getSessionData } from './utils';
import { Navigate } from 'react-router-dom';
import EmailUpload from "./EmailUpload";

const apiUrl = process.env.REACT_APP_API_URL;
const user = getSessionData('user');

const EmailAccount = () => {
  const [loading, setLoading] = useState(false);
  const [emailAccounts, setEmailAccounts] = useState([]);
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Show 5 items per page

  // Fetch Email Accounts
  const GetEmailAccounts = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/email-accounts-list?user_id=${user?.id}`, {
        headers: { 'Accept': 'application/json' }
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setEmailAccounts(data);
      } else {
        console.error('Unexpected API response:', data);
      }
    } catch (error) {
      console.error('Error fetching email accounts:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle Account Deletion
  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this Account?')) {
      try {
        setLoading(true);
        const response = await fetch(`${apiUrl}/api/email-accounts-list/${id}`, {
          method: 'DELETE',
        });
        if (!response.ok) throw new Error('Failed to delete Account');
        setEmailAccounts(emailAccounts.filter((account) => account.id !== id));
        alert('Account deleted successfully');
      } catch (error) {
        console.error('Error deleting Account:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (user?.id) {
      GetEmailAccounts();
    }
  }, [user]);

  // Pagination Logic
  const totalPages = Math.ceil(emailAccounts.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = emailAccounts.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div>
      <div className="tophead_mainsec ">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="title_mainsec">
              <h2>Gmail Accounts</h2>
            </div>
          </div>
        </div>
      </div>
       <div className="container mt-3">
         <div className="row text-center">
          <EmailUpload refreshData={GetEmailAccounts} />
          </div>
       </div>
      <div className="email_listing_mainsec ">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="card-body">
              <div className="table_mainsec">
                {loading ? (
                  <div className="loader_mainsec">
                    <div className="spinner"></div>
                  </div>
                ) : (
                  <div className="table-responsive">
                    {paginatedData.length > 0 ? (
                      <>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Email Address</th>
                              <th>Imap Username</th>
                              <th>Imap Password</th>
                              <th>Imap Host</th>
                              <th>Imap Port</th>
                              <th className="text-end">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paginatedData.map((account, index) => (
                              <tr key={account.id}>
                                <td>{startIndex + index + 1}</td> {/* Sequential numbering */}
                                <td>{`${account.first_name} ${account.last_name}`}</td>
                                <td>{account.email}</td>
                                <td>{account.imap_username}</td>
                                <td>{account.imap_password}</td>
                                <td>{account.imap_host}</td>
                                <td>{account.imap_port}</td>
                                <td>
                                  <div className="btn_mainsec justify-content-end">
                                    <button className="remove_btn btn_style" onClick={() => handleDelete(account.id)}>
                                      Delete
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        {/* Pagination Controls */}
                        <div className="pagination justify-content-end">
                          <button
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                          >
                            Previous
                          </button>
                          <span>
                            Page {currentPage} of {totalPages}
                          </span>
                          <button
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                            Next
                          </button>
                        </div>
                      </>
                    ) : (
                      <p>No reply messages found</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailAccount;
