import React, { useState, useRef, useEffect } from 'react';
import Papa from 'papaparse';
import { getSessionData } from './utils';
import { useSearchParams, Navigate, Link} from 'react-router-dom';
// import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const GOOGLE_CLIENT_ID = "942057149457-eeesoepdn2ib2pi2qacfv4ri409jqt55.apps.googleusercontent.com";


const ConnectAccounts = () => {
 const user = getSessionData('user');

  useEffect(() => {
    // Load the gapi script dynamically when the component mounts
    const loadGoogleApi = () => {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js";
      script.onload = () => {
        window.gapi.load("client:auth2", initClient);
      };
      document.body.appendChild(script);
    };

    // Initialize the Google API client with your credentials
    const initClient = () => {
      window.gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: "https://www.googleapis.com/auth/gmail.send",
        response_type:'code'
      }).then(() => {
        console.log("Google API client initialized");
      }).catch((error) => {
        console.error("Error initializing Google API client:", error);
      });
    };

    loadGoogleApi();
  }, []);

  // Function to handle login and get authorization code
  const handleSignIn = () => {
    const userId = getSessionData('user')?.id;
    const clientId = GOOGLE_CLIENT_ID;
    const scope = "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.modify profile email";
    // const redirectUri = "https://admin.automate-emailscale.io/api/g-suite-save-smtp";
    const redirectUri = `${apiUrl}/api/g-suite-save-smtp`;
    const state = encodeURIComponent(JSON.stringify({ user_id: userId }));
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline&prompt=consent&state=${state}`;
    window.location.href = authUrl;
  };

  if(!user) {
    return <Navigate to="/login" />;
  }

  if(user.warm_up_tool  === '0') {
    alert("you don't have to access this tool");
    return <Navigate to="/dashboard" />;
  }


  return (
    <>
    <div className="addemails_mainbox">
      <div className="container">
          <div className="row">
                <div className="overall_performance ">
                      <div className="connect_accounts_sec">
                        <div className="connect_accounts_boxsec">
                          <div className="connect_accounts_box">
                            <div className="topbox">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chrome w-8 h-8 text-primary-500"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="4"></circle><line x1="21.17" x2="12" y1="8" y2="8"></line><line x1="3.95" x2="8.54" y1="6.06" y2="14"></line><line x1="10.88" x2="15.46" y1="21.94" y2="14"></line></svg>
                              
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check-circle2 w-4 h-4 mr-1"><circle cx="12" cy="12" r="10"></circle><path d="m9 12 2 2 4-4"></path></svg> 
                                
                                Connected
                              </span>
                            </div>
                            
                            <div className="infobox">
                              <h3>Google Account</h3>
                              <p>Connect your Gmail or Google Workspace account</p>
                              <div className="btn_mainsec">
                               <button className="btn_style" onClick={handleSignIn}>Connect Google</button>
                              </div>
                            </div>
                          </div>
                          <div className="connect_accounts_box">
                             <div className="topbox">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail w-8 h-8 text-primary-500"><rect width="20" height="16" x="2" y="4" rx="2"></rect><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path></svg>                            
                              <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check-circle2 w-4 h-4 mr-1"><circle cx="12" cy="12" r="10"></circle><path d="m9 12 2 2 4-4"></path></svg> 
                              Connected
                              </span>
                             </div>
                          
                          <div className="infobox">
                            <h3>Microsoft Account</h3>
                            <p>Connect your Outlook or Microsoft 365 account</p>
                            <div className="btn_mainsec">
                              <a className="btn_style" href="#">Connect Microsoft</a>
                            </div>
                          </div>
                          </div>
                          <div className="connect_accounts_box">
                          <div className="topbox">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-server w-8 h-8 text-primary-500"><rect width="20" height="8" x="2" y="2" rx="2" ry="2"></rect><rect width="20" height="8" x="2" y="14" rx="2" ry="2"></rect><line x1="6" x2="6.01" y1="6" y2="6"></line><line x1="6" x2="6.01" y1="18" y2="18"></line></svg>                            
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-alert-circle w-4 h-4 mr-1"><circle cx="12" cy="12" r="10"></circle><line x1="12" x2="12" y1="8" y2="12"></line><line x1="12" x2="12.01" y1="16" y2="16"></line></svg>
                              Not Connected
                            </span>
                          </div>
                          
                          <div className="infobox">
                            <h3>SMTP Server</h3>
                            <p>Connect your custom SMTP server</p>
                            
                            <div className="btn_mainsec">
                             <Link className="btn_style" to={`${homeUrl}/add-email`}>Configure SMTP</Link> 
  
                            </div>
                          </div>
                          </div>
                        </div>
                       </div>
                   </div>
              </div>
          </div>
      </div>
    </>
  );
};


export default ConnectAccounts;