import { useState } from 'react';
import Papa from 'papaparse';
import { Navigate } from 'react-router-dom';
import { getSessionData } from './utils';

const apiUrl = process.env.REACT_APP_API_URL;
const apiUrl1 = 'https://staging.myemail-verifier.com:3002';
function Emailchecker() {
  const user = getSessionData('user');
  const [message, setMessage] = useState(false);
  const [message1, setMessage1] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [emaildata, setEmaildata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState(null);
  const [results, setResults] = useState([]);
  const [results1, setResults1] = useState([]);
  const [email, setEmail] = useState('');
  const [email1, setEmail1] = useState(false);
  const [validemail, setValidemail] = useState(false);
  const [invalidemail, setInvalidemail] = useState(0);
  const [totalemail, setTotalemail] = useState(0);
  const [fileerror, setFileerror] = useState(false);

  const handleFileChange = (event) => {
    setFilename(event.target.files[0].name);
    setCsvFile(event.target.files[0]);
  };

  const handleUpload = () => {
    const allowedTypes = ["text/csv", "application/vnd.ms-excel"];
    if (!allowedTypes.includes(csvFile.type)) {
        setFileerror(true);
        return;
    }
    if (csvFile) {
      const reader = new FileReader();

      reader.onload = async function (e) {
        const csvData = e.target.result;
        await sendCSVData(csvData);
      };

      reader.readAsText(csvFile);
    } else {
      alert('Please select a CSV file');
    }
  };

  const sendRequest = async (rk) => {
    const endpoint = 'emailchecker';
    const response = await fetch(`${apiUrl1}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: rk }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data1 = await response.json();
    return data1;
  };

  const sendCSVData = async (csvData) => {
    const timer1 = setTimeout(() => {
      setMessage1('Still at It!');
      setMessage('I’m still crunching those numbers. Thanks for hanging in there!');
    }, 15 * 60 * 1000);

    const timer2 = setTimeout(() => {
      setMessage1('Almost There!');
      setMessage('You’re doing great! Just a little more time.');
    }, 30 * 60 * 1000);

    const timer3 = setTimeout(() => {
      setMessage1('Final Stretch!');
      setMessage('We’re in the home stretch now!');
    }, 45 * 60 * 1000);

    Papa.parse(csvData, {
      header: false,
      skipEmptyLines: true,
      complete: async (results) => {
        const rows = results.data;
        if((rows[0][0] === "Lead ID" ||
          rows[0][0] === 'WarpLeadsId') &&
          rows[0][1] === "FirstName" &&
          rows[0][2] === "LastName" &&
          rows[0][3] === "JobTitle" &&
          rows[0][4] === "PersonLinkedInUrl" &&
          rows[0][5] === "PersonCountry" &&
          rows[0][6] === "CompanyName" &&
          rows[0][7] === "CompanyIndustry" &&
          rows[0][8] === "CompanySize" &&
          rows[0][9] === "CompanyWebsite" &&
          rows[0][10] === "CompanyCountry" &&
          rows[0][11] === "CompanyCity" &&
          rows[0][12] === "WorkEmail" &&
          rows[0][13] === "PersonalEmail" &&
          rows[0][14] === "Phone1" &&
          rows[0][15] === "Phone2") {
          
          save_json_file(rows);
          setEmaildata([]);
          setResults([]);
          setLoading(true);
          let v_email = 0;
          let in_email = 0;
          /*
          for (let i = 1; i < rows.length; i++) {
            if (rows[i].length > 0) {
              try {
                const rk = rows[i];
                if (rk[0] !== '') {
                  const result = await sendRequest(rk);
                  if (result.results.length > 0 && result.results[0].status) {
                    v_email += 1;
                    setResults((prevResults) => [
                      ...prevResults,
                      {
                        WarpLeadsId: rk[0],
                        FirstName: rk[1],
                        LastName: rk[2],
                        JobTitle: rk[3],
                        PersonLinkedInUrl: rk[4],
                        PersonCountry: rk[5],
                        CompanyName: rk[6],
                        CompanyIndustry: rk[7],
                        CompanySize: rk[8],
                        CompanyWebsite: rk[9],
                        CompanyCountry: rk[10],
                        CompanyCity: rk[11],
                        WorkEmail: rk[12],
                        PersonalEmail: rk[13],
                        Phone1: rk[14],
                        Phone2: rk[15],
                        Status: 'valid',
                      },
                    ]);
                  } else {
                    in_email += 1;
                    setResults1((prevResults) => [
                      ...prevResults,
                      {
                        WarpLeadsId: rk[0],
                        FirstName: rk[1],
                        LastName: rk[2],
                        JobTitle: rk[3],
                        PersonLinkedInUrl: rk[4],
                        PersonCountry: rk[5],
                        CompanyName: rk[6],
                        CompanyIndustry: rk[7],
                        CompanySize: rk[8],
                        CompanyWebsite: rk[9],
                        CompanyCountry: rk[10],
                        CompanyCity: rk[11],
                        WorkEmail: rk[12],
                        PersonalEmail: rk[13],
                        Phone1: rk[14],
                        Phone2: rk[15],
                        Status: 'invalid',
                      },
                    ]);
                  }
                }
              } catch (error) {
                console.error('Error processing row:', i, error);
              }
            }
          } */
          setValidemail(true);
          setInvalidemail(in_email);
          setTotalemail(v_email + in_email);
          clearTimeout(timer1);
          clearTimeout(timer2);
          clearTimeout(timer3);
          setMessage(false);
          setMessage1(false);
          setLoading(false);
        }
        else {
          setFileerror(true);
          return true;
        }
      },
    });
  };

  const save_json_file = async (rows) => {
    // setValidemail(false);
    try {
      const time = Date.now()
      const endpoint = 'save-json';
      const payload = {
        email: user.email,
        userid: user.id,
        rows: rows,
        status: 0,
        time:time
      };

      console.log('Sending payload to API:', payload);

      const response = await fetch(`${apiUrl1}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('Server error:', errorDetails);
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('API Response:', data);
      setLoading(false);
      setValidemail(true);
      setFilename(null);
    } catch (error) {
      console.error('Fetch Error:', error.message);
    }
  };

  const downloadCSV = () => {
    const edata = results;
    if (edata.length > 0) {
      const headers = Object.keys(edata[0]).join(',') + '\n';
      const rows = edata
        .map((item) =>
          Object.values(item)
            .map((value) => {
              if (typeof value === 'string') {
                value = value.replace(/"/g, '""');
                if (value.includes(',') || value.includes('\n') || value.includes('"')) {
                  value = `"${value}"`;
                }
              }
              return value;
            })
            .join(',')
        )
        .join('\n');
      const csvContent = headers + rows;

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.href = url;
      link.setAttribute('download', 'valid-emails.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('No data available to export.');
    }
  };

  const downloadCSV1 = () => {
    const edata1 = results1;
    if (edata1.length > 0) {
      const headers = Object.keys(edata1[0]).join(',') + '\n';
      const rows = edata1
        .map((item) =>
          Object.values(item)
            .map((value) => {
              if (typeof value === 'string') {
                value = value.replace(/"/g, '""');
                if (value.includes(',') || value.includes('\n') || value.includes('"')) {
                  value = `"${value}"`;
                }
              }
              return value;
            })
            .join(',')
        )
        .join('\n');
      const csvContent = headers + rows;

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.href = url;
      link.setAttribute('download', 'invalid-emails.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('No data available to export.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail1(false);
    const endpoint = 'singleemailchecker';
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl1}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      setLoading(false);
      const data = await response.json();
      setEmail1(data.results[0]);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  if(!user) {
    return <Navigate to="/login" />;
  }

  if(user.email_tool  === '0') {
    alert("you don't have to access this tool");
    return <Navigate to="/dashboard" />;
  }

  const handleClosePopup = () => {
    setValidemail(false);
    setInvalidemail(0);
    setFileerror(false);
  };

  return (
  <>
    <div className="container">
      {/*<div className="tophead_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="title_mainsec">
              <h2>Email Checker</h2>
            </div>
          </div>
        </div>
      </div>*/}
      <div className="emailchecker_mainsec">
        <div className="step_mainsec">
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
					<div className="title_mainsec">
						<h2>Email Verification</h2>
					</div>
				</div>
			</div>
			
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
					<div className="step_boxsec stepone active">
						<div className="step_txtbox">
							<div className="step_txt">
								<h2>1</h2>
							</div>
							
							<div className="step_title">
								<h3>Upload Your CSV File</h3>
							</div>
						</div>
						
						<div className="contnt_box">
							<p>Upload your CSV file containing email addresses. Make sure:</p>
							
							<ul>
								<li>Numbers are in column 1</li>
								<li>File contains no more than 20,000 leads per run</li>
								<li>File format matches our template</li>
							</ul>
						</div>
						
						<div className="download_filebox">
							<a className="btn_style download_s_btn" href="https://staging.automate-emailscale.io/Email-erfication-Tool-Template.csv" download>
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-download w-4 h-4"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" x2="12" y1="15" y2="3"></line></svg>
								
								Download Sample File
							</a>
						</div>
						
						<div className="uploadbox_mainsec">
							<div className="fileinput_sec">
								<div className="upload_box">
									<input
									  type="file"
									  accept=".csv"
									  onChange={handleFileChange}
									  required
									  className="form-control"
									/>
									
									<div className="upload_txt">
										<i className="far fa-file-alt"></i>
										<div className="contnt_txt">
											{filename ? <p>{filename}</p> : <p>Drag & drop your CSV file here, or click to select.</p>}
											<p>Maximum file size: 50MB</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div className="step_boxsec steptwo">
						<div className="step_txtbox">
							<div className="step_txt">
								<h2>2</h2>
							</div>
							
							<div className="step_title">
								<h3>Processing Time</h3>
							</div>
						</div>
						
						<div className="contnt_box">
							<p><i className="far fa-clock"></i> Approximately 15-20 minutes for 20,000 leads</p>
						</div>
					</div>
					
					<div className="step_boxsec steptwo">
						<div className="step_txtbox">
							<div className="step_txt">
								<h2>3</h2>
							</div>
							
							<div className="step_title">
								<h3>Download Results</h3>
							</div>
						</div>
						
						<div className="contnt_box">
							<p>We'll email you when your file is ready. Download and import into your sending tools.</p>
						</div>
					</div>
				</div>
				
				<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
					<div className="btn_mainsec">
						<button className="btn_style" onClick={handleUpload}>Upload CSV</button>
					</div>
				</div>
			</div>
		
			{/*<div className="container">
				<div className="row">
					<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<div className="title_box">
							<h1>How to Use Emailscale<br/> Email Verification</h1>
						</div>
					</div>
				</div>
          
				<div className="row">
					<div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
						<div className="step_boxsec">
							<div className="step_txt">
								<h2>01</h2>
							</div>
                  
							<div className="title_box">
								<h3>Upload Your CSV File</h3>
							</div>
                  
							<div className="contnt_box">
								<p>Ensure your file is correctly formatted (see example) before uploading it to the platform. Each row in column 1 should contain a unique sequential number that matches the number of leads (e.g., 1, 2, 3, etc., up to the total number of leads you have, even if in the thousands). Proper formatting helps the verification process run smoothly.</p>
							</div>
						</div>
					</div>
              
					<div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
						<div className="step_boxsec">
							<div className="step_txt">
								<h2>02</h2>
							</div>
                  
							<div className="title_box">
								<h3>Sit Back and Relax</h3>
							</div>
                  
							<div className="contnt_box">
								<p>Depending on the size of your email list, the process may take some time. We prioritize accuracy, so larger lists will naturally take longer.</p>
							</div>
						</div>
					</div>
              
					<div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
						<div className="step_boxsec">
							<div className="step_txt">
								<h2>03</h2>
							</div>
                  
							<div className="title_box">
								<h3>Download and Upload</h3>
							</div>
                  
							<div className="contnt_box">
								<p>Once the verification is complete, download the cleaned spreadsheet. You can now upload this verified list to your email sending platform, ensuring higher deliverability and fewer bounces.</p>
							</div>
						</div>
					</div>
				</div>
			</div> */}
        </div>
		
        {/*<div className="uploadbox_mainsec">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<div className="fileinput_sec">
							<div className="upload_box">
								<input
								  type="file"
								  accept=".csv"
								  onChange={handleFileChange}
								  required
								  className="form-control"
								/>
								
								<div className="upload_txt">
									<i className="fas fa-upload"></i>
									<div className="contnt_txt">
										{filename ? <p>{filename}</p> : <p>Choose a file or drag it here.</p>}
									</div>
								</div>
							</div>
							
							<div className="btn_mainsec">
								<button className="btn_style" onClick={handleUpload}>Upload CSV</button>
								<a className="btn_style download_s_btn" href="https://staging.automate-emailscale.io/Email-erfication-Tool-Template.csv" download>Download Sample File</a>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>*/}

        {loading && (
          <div className="loader_mainsec">
        <div className="loader_innerbox">
          <div className="spinner_box">
            <div className="spinner"></div>
          </div>
          { message ? (
            <div className="loader_contnt">
              <p>Please wait.....</p>
              { message1 ? <p> {message1} </p> : null }
              { message ? <p> {message} </p> : null }
            </div> 
            ) : (
            <div className="loader_contnt">
              <p>Please wait.....</p>
              <p>Hold Tight – We’re Scanning Your File!</p>
              <p>Please don’t close this window – we're carefully processing your list and making sure everything is just right.</p>
              <br/>
            </div> 
          )}
        </div>
          </div>
        )}

        {validemail && (
          <div>
            <div className="modal fade infopopupModal in show" id="infopopupModal" tabindex="-1" aria-labelledby="exampleModalLabel" style={{display:'block'}}>
              <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                <div className="closebtn">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClosePopup}></button>
                </div>
                 
                <div className="contnt_mainsec">
                  <div className="total_emailbox">
                  <h4>Thank You – We’ve Received Your File!</h4>
                  <p>Your file has been successfully submitted, and we’ll send you an email to your verified address once the process is complete in approx 15minutes.</p>
                  <p>Thanks for your patience!</p>
                  </div>
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          )}

          {fileerror && (
          <div>
            <div className="modal fade infopopupModal in show" id="infopopupModal" tabindex="-1" aria-labelledby="exampleModalLabel" style={{display:'block'}}>
              <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                <div className="closebtn">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClosePopup}></button>
                </div>
                 
                <div className="contnt_mainsec">
                  <div className="total_emailbox">
                  <h4 className="text-start">Oops!</h4>
                  <p className="text-start">It looks like you’ve uploaded a file in the wrong format. Please download our sample file and try again.</p>
                  </div>
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          )}
      </div>
    </div>
    </>
  );
}

export default Emailchecker;
