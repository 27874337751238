import React, { useEffect, useState } from 'react';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { saveSessionData, getSessionData } from '../components/utils';

const homeUrl = process.env.REACT_APP_HOME_URL;
const user = getSessionData('user');

const logout = () => {
  saveSessionData('user', null);
  window.location.href = homeUrl + '/login';
};

const TopHead = () => {
  const { campaignId } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isDashboard, setIsDashboard] = useState(true);
  const [pageTitle, setPageTitle] = useState('');
  const [pageIcon, setPageIcon] = useState(<i className="fas fa-file"></i>);

  // Logo icon for dashboard
  const logoIcon = <i className="far fa-envelope auto-logo"></i>;

  // Back icon for non-dashboard pages
  const backIcon = <i className="fas fa-arrow-left"></i>;

  // Function to determine the title based on the path
  const getTitle = (path) => {
    switch (path) {
      case '/campaigns': return 'Email Warm-up';
      case '/analytics': return 'Analytics';
      case '/add-email': return 'Email Warm-up';
      case '/reply-messages': return 'Reply Messages';
      case '/warmup-dashboard': return 'Email Warm-up';
      case '/emailchecker': return 'Email Verification';
      case '/search-domain': return 'Inbox Setup';
      case '/domain-list': return 'Inbox Setup';
      case '/connect-accounts': return 'Connect Accounts';  
      case `/campaign-emails/${campaignId}`: return 'Campaign Emails';
      default: return '';
    }
  };

  // Function to get the correct icon
  const getIcon = (title) => {
    switch (title) {
      case 'Campaigns': return <i className="fas fa-bullhorn"></i>;
      case 'Analytics': return <i className="fas fa-chart-line"></i>;
      case 'Email Warm-up': return <i className="fas fa-bolt"></i>;
      case 'Reply Messages': return <i className="fas fa-bolt"></i>;
      case 'Email Warm-up': return <i className="fas fa-bolt"></i>;
      case 'Email Verification': return <i className="far fa-envelope"></i>;
      case 'Inbox Setup': return <i className="fas fa-inbox"></i>;
      case 'Domain List': return <i className="fas fa-list"></i>;
      case 'Campaign Emails': return <i className="fas fa-bolt"></i>;
      case 'Connect Accounts': return <i className="fas fa-bolt"></i>;
      default: return '';
    }
  };

  useEffect(() => {
    const title = getTitle(location.pathname);
    setPageTitle(title);
    setPageIcon(getIcon(title));
    setIsDashboard(location.pathname === '/dashboard');
  }, [location.pathname, campaignId]);

  return (
    <>
      <div className="tophead_mainsec tophead_mainsec_global">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="title_mainsec">
              {isDashboard ? (
                <NavLink to="/dashboard" className="navbar-brand auto-logo-text d-flex">
                  {logoIcon} emailscale.io
                </NavLink>
              ) : currentPath === "/add-email" ? (
                <NavLink to="/warmup-dashboard" className="navbar-brand d-flex align-items-center">
                 <i className="fas fa-arrow-left"></i> <span className="ms-2">Back to Email Warmup</span>
                </NavLink>
              ) : currentPath === `/campaign-emails/${campaignId}` ? (
                <NavLink to="/warmup-dashboard" className="navbar-brand d-flex align-items-center">
                  {backIcon} <span className="ms-2">Back to Email Warmup</span>
                </NavLink>
              ) : (
                <NavLink to="/dashboard" className="navbar-brand d-flex align-items-center">
                  {backIcon} <span className="ms-2">Back to Dashboard</span>
                </NavLink>
              )}
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="profile_box_mainsec">
                <div className="nav-item dropdown">
                  {isDashboard ? (
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <span className="profile_imgbox">{user.name}</span>
                    </a>
                  ) : (
                    <span className="page-title d-flex align-items-center">
                      {pageIcon} <span className="ms-2">{pageTitle}</span>
                    </span>
                  )}

                  {isDashboard && (
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li><NavLink className="dropdown-item" to="/dashboard"><i className="fas fa-user-circle"></i> My Account</NavLink></li>
                      <li><NavLink className="dropdown-item" onClick={logout}><i className="fas fa-sign-out-alt"></i> Logout</NavLink></li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
      <div className="global-page-top-bar">
        <div className="container">
            <div className="row">
              <div className="menu_topbar">
                  {(location.pathname === '/warmup-dashboard' || 
                  location.pathname === '/connect-accounts' || 
                  location.pathname === '/analytics' || 
                  location.pathname === '/campaigns' || 
                  location.pathname === '/reply-messages' ||
                  location.pathname === '/email-accounts') && (
                  <div className="menu_box">
                    <ul>
                    <li>
                       <NavLink to="/warmup-dashboard" className={({ isActive }) => isActive ? "active" : ""}>
                         <span className="name_txt">Warmup Dashboard</span>
                        </NavLink>
                      </li>
                      
                      <li>
                        <NavLink to="/connect-accounts" className={({ isActive }) => isActive ? "active" : ""}>
                         <span className="name_txt">Connect Accounts</span>
                        </NavLink>
                      </li>
                      {/*<li>
                        <NavLink to="/campaigns" className={({ isActive }) => isActive ? "active" : ""}>
                         <span className="name_txt">Email Accounts</span>
                        </NavLink>
                      </li>*/}
                      <li>
                        <NavLink to="/reply-messages" className={({ isActive }) => isActive ? "active" : ""}>
                         <span className="name_txt">Reply Messages</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
                  {(location.pathname === '/search-domain' || 
                  location.pathname === '/domain-list') && (
                  <div className="menu_box">
                    <ul>
                      {/*<li>
                        <NavLink to="/search-domain" className={({ isActive }) => isActive ? "active" : ""}>
                         <span className="name_txt">Search Domain</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/domain-list" className={({ isActive }) => isActive ? "active" : ""}>
                         <span className="name_txt">Domain List</span>
                        </NavLink>
                      </li>*/}
                    </ul>
                  </div>
                )}
              </div>
            </div>
        </div>
      </div>
    </>  
  );
};

export default TopHead;

