import React, { useState, useRef, useEffect } from 'react';
import { getSessionData } from './utils';
import { useSearchParams } from 'react-router-dom';
const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const user = getSessionData('user');
const UpdateSubject = () => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [messageType, setMessageType] = useState(1);
  const [common_subject, setCommonSubject] = useState('');
  const [common_message, setCommonMessage] = useState('');
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [searchParams] = useSearchParams();

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/get-subject?ids=${searchParams.get('ids')}`);
      if (!response.ok) throw new Error('Failed to fetch campaigns');
      const data = await response.json();
      setCampaigns(data.campaigns);
      // setCommonSubject(data.common_subject);
      // setCommonMessage(data.common_message);
      
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const handleChangevalues = (e) => {
    const value = e.target.value;
    const type = e.target.dataset.type;
    const ind = e.target.dataset.ind;

    // Create a copy of the campaigns array
    const updatedCampaigns = [...campaigns];

    // Update the specific campaign
    updatedCampaigns[ind] = {
      ...updatedCampaigns[ind],
      [type]: value,
    };

    // Set the updated array to the state
    setCampaigns(updatedCampaigns);
    
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (messageType === 2) {
	    // Validation for Unique Message fields
	    const isInvalid = campaigns.some(campaign => !campaign.subject?.trim() || !campaign.campaign_message?.trim());

	    if (isInvalid) {
	      alert("Please fill in all Subject and Message fields before submitting.");
	      return;
	    }
	  }
    setLoading(true);

    const endpoint = 'update-subject';
    try {
      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          campaigns,
          common_subject,
          common_message,
          messageType
        }),
      });
      setLoading(false);    
      window.location.href = `${homeUrl}/campaigns`;
    } 
    catch (error) {
        console.error(error);
        setLoading(false);
    }
  };

  return (
    <>
      <div className="tophead_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="title_mainsec">
              <h2>Update subject and message</h2>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          	<p>Your email accounts have been successfully added. Please choose the message type you prefer. If no selection is made, the default message type will be set to "Predefined Template." You may also close the window if no changes are needed.</p>
          </div>
        </div>
      </div>
      <div className="subject_message_mainsec email_listing_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="card-body">
							<div className="select_email_type">
								<div className="tabbox">
									<label className="radio_custmbtn">
										<input type="radio" name="radio" checked={messageType === 1} onChange={() => setMessageType(1)} />
										<span className="txt">Predefine Template</span>
									</label>
								  
									<label className="radio_custmbtn">
										<input type="radio" name="radio" checked={messageType === 2} onChange={() => setMessageType(2)} />
										<span className="txt">Unique Message</span>
									</label>
									{/*
									<label className="radio_custmbtn">
										<input type="radio" name="radio" checked={messageType === 3} onChange={() => setMessageType(3)} />
										<span className="txt">Common Message</span>
									</label>
									*/}
								</div>
								
								<div className="tabcontnt_box">
									{messageType === 1 ? (<div className="predefine_template">
										<div className="title_mainsec">
				              <h2>Predefine Template</h2>
				            </div>
				            <div className="table_mainsec">
											<div className="table-responsive1">
												<table className="table">
													<thead>
														<tr>
															<th>Email</th>
														</tr>
													</thead>
													
													<tbody>
													  {campaigns.length > 0 ? (
														campaigns.map((campaign, index) => (
														<tr key={campaign.id}>
														  <td>
															<label>{campaign.smtp_username}</label>
														  </td>
														</tr>
													  ))
													  ) : (
														<tr>
														  <td colSpan="3">No campaigns found</td>
														</tr>
													  )}
													</tbody>
												</table>
											</div>
										</div>
										<div className="btn_mainsec">
										  <button className="btn_style" onClick={handleSubmit}>
											Save
										  </button>
										</div>
									</div>) : (null) }
									
									{messageType === 2 ? (<div className="uniquemsg_box">
										<div className="title_mainsec">
				              <h2>Unique Message</h2>
				            </div>
										<div className="table_mainsec">
											<div className="table-responsive">
												<table className="table">
													<thead>
														<tr>
															<th>Email</th>
															<th>Subject</th>
															<th>Message</th>
														</tr>
													</thead>
													
													<tbody>
													  {campaigns.length > 0 ? (
														campaigns.map((campaign, index) => (
														<tr key={campaign.id}>
														  <td>
															<label>{campaign.smtp_username}</label>
														  </td>
														  <td>
															<input 
															  type="text" 
															  className="form-control" 
															  value={campaign.subject}
															  data-type="subject"
															  data-ind={index}
															  onChange={handleChangevalues}
															/>
														  </td>
														  <td>
															<textarea 
															  className="form-control"
															  data-type="campaign_message"
															  data-ind={index}
															  onChange={handleChangevalues}
															>{campaign.campaign_message}</textarea>
														  </td>
														</tr>
													  ))
													  ) : (
														<tr>
														  <td colSpan="3">No campaigns found</td>
														</tr>
													  )}
													</tbody>
													{campaigns.length > 0 ? (
													
													<tfoot>
														<tr>
														  <td colSpan="3">
															<div className="btn_mainsec">
															  <button className="btn_style" onClick={handleSubmit}>
																Save Changes
															  </button>
															</div>
														  </td>
														</tr>
													</tfoot>
													) : (
													null
													)}
												</table>
											</div>
										</div>
									</div>) : (null)}
									
									{messageType === 3 ? (<div className="commonmsg">
										<div className="title_mainsec">
											<h2>Common Message</h2>
										</div>
										
										<div className="formbox">
											<div className="row">
												<div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
													<div class="mb-3">
														<label for="exampleFormControlInput1" className="form-label">Subject</label>
														<input 
															type="text" 
															className="form-control" 
															id="exampleFormControlInput1"
															value={common_subject}
															onChange={(e) => setCommonSubject(e.target.value)}
														  />
													</div>
													
													<div class="mb-3">
														<label for="exampleFormControlTextarea1" className="form-label">Message</label>
													<textarea 
														className="form-control" 
														id="exampleFormControlTextarea1"
														onChange={(e) => setCommonMessage(e.target.value)}
														rows="3">{common_message}
													</textarea>
												</div>
												
												<div className="btn_mainsec">
													<button className="btn_style" onClick={handleSubmit}>
														Save Changes
													</button>
												</div>
											</div>
										</div>
										
										</div>
									</div>) : (null)}
								</div>
							</div>
            </div>
          </div>
        </div>
      </div>
      {loading ? <div className="loader_mainsec">
        <div className="spinner"></div>
      </div> : <div></div>}
    </>
  );
};

export default UpdateSubject;
